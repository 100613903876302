/* =============================================================================
   TABLES (elements/_tables.scss)
   ========================================================================== */

/**
 * 1. Ensure tables fill up as much space as possible.
 */

table {
	width: 100%; /* [1] */
}
