/* =============================================================================
   BUTTONS (components/_buttons.scss)
   ========================================================================== */

.btn {
	font-family: $font-family-secondary;
	font-weight: 500;
	display: inline-block;
	margin: 0 0 1.25rem 0;
}



/* Variants
   ========================================================================== */

.btn--full-width {
	display: block;
	width: 100%;
}

.btn--rounded {
	border-radius: 3.125rem;
}

.btn--dark {
	color: $color-white;
	background-color: $color-dark-blue;
	padding: .5625rem 1.5625rem;
	transition: color .2s, background-color .2s;

	@include media-breakpoint-up(md) {
		padding: .8125rem 2.1875rem;
	}

	&:hover,
	&:focus,
	&:active {
		color: $color-dark-blue;
		background-color: $color-yellow;
	}
}
