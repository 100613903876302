/* =============================================================================
   HEADINGS (elements/_headings.scss)
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

h1, h2, h3, h4, h5, h6 {
	font-family: $font-family-secondary;
}

h1 {
	font-size: $font-size-h1;
}

h2 {
	font-size: $font-size-h2;
}

h3 {
	font-size: $font-size-h3;
}

h4 {
	font-size: $font-size-h4;
}

h5 {
	font-size: $font-size-h5;
}

h6 {
	font-size: $font-size-h6;
}
