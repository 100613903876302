/* =============================================================================
   CORE (settings/_core.scss)
   ========================================================================== */

/**
 * Core and setup settings.
 */

/* Colors
   ========================================================================== */

$color-black: #000 !default;
$color-white: #fff !default;
$color-light-blue: #E8F2FC !default;
$color-dark-blue: #253951 !default;
$color-yellow: #f6d000 !default;
$color-light-yellow: #FCFDEA !default;
$color-light-green: #F0FAE8 !default;
$color-light-purple: #FDE9F8 !default;
$color-light-red: #FFECE7 !default;
$color-base: $color-dark-blue !default;

$colors: (
	'black': $color-black,
	'white': $color-white,
	'light-blue': $color-light-blue,
	'dark-blue': $color-dark-blue,
	'yellow': $color-yellow,
) !default;



/* Typography
   ========================================================================== */

/**
 * Base
 */

$font-size-base: 16px !default;
$font-size-base-mobile: 14px !default;
$line-height-base: 1.65 !default;
$font-family-base: 'Noto Sans', sans-serif !default;
$font-family-secondary: 'Montserrat', sans-serif !default;

/**
 * Headings
 */

$font-size-h1: 2.25rem !default;
$font-size-h2: 1.75rem !default;
$font-size-h3: 1.5rem !default;
$font-size-h4: 1.25rem !default;
$font-size-h5: 1.125rem !default;
$font-size-h6: 1rem !default;



/* Spacing
   ========================================================================== */

$spacer-base: 1.25rem !default;

$spacers: (
	'1': .625rem,
	'3': 1.875rem,
	'15': 9.375rem,
) !default;


/* Breakpoints
   ========================================================================== */

$breakpoints: (
	'xs': 0,
	'sm': 576px,
	'md': 768px,
	'lg': 992px,
	'xl': 1230px
) !default;
