/* =============================================================================
   MAIN
   ========================================================================== */

/* Settings
   ========================================================================== */

@import 'settings/core';



/* Tools
   ========================================================================== */

@import 'tools/breakpoints';
@import 'tools/clearfix';



/* Generic
   ========================================================================== */

@import 'generic/box-sizing';
@import 'generic/normalize';
@import 'generic/reset';



/* Elements
   ========================================================================== */

@import 'elements/page';
@import 'elements/headings';
@import 'elements/images';
@import 'elements/links';
@import 'elements/media';
@import 'elements/tables';



/* Objects
   ========================================================================== */

@import 'objects/layout';
@import 'objects/list-bare';
@import 'objects/list-inline';



/* Components
   ========================================================================== */

@import 'components/buttons';
@import 'components/demo-item';



/* Utilities
   ========================================================================== */

@import 'utilities/backgrounds';
@import 'utilities/clearfix';
@import 'utilities/headings';
@import 'utilities/spacing';
@import 'utilities/typography';
