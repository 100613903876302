/* =============================================================================
   LIST-INLINE (objects/_list-inline.scss)
   ========================================================================== */

/**
 * The list-inline object simply displays a list of items in one line.
 */

.o-list-inline {
	margin-left: 0;
	list-style: none;
}

.o-list-inline__item {
	display: inline-block;
}
