/* =============================================================================
   HEADINGS (utilities/_headings.scss)
   ========================================================================== */

/* stylelint-disable declaration-no-important */

/**
 * Redefine all of our basic heading styles against utility classes so as to
 * allow for double stranded heading hierarchy, e.g. we semantically need an H2,
 * but we want it to be sized like an H1.
 */

.u-h1, .u-h2, .u-h3, .u-h4, .u-h5, .u-h6 {
	font-family: $font-family-secondary !important;
}

.u-h1 {
	font-size: $font-size-h1 !important;
}

.u-h2 {
	font-size: $font-size-h2 !important;
}

.u-h3 {
	font-size: $font-size-h3 !important;
}

.u-h4 {
	font-size: $font-size-h4 !important;
}

.u-h5 {
	font-size: $font-size-h5 !important;
}

.u-h6 {
	font-size: $font-size-h6 !important;
}
