/* =============================================================================
   RESET (generic/_reset.scss)
   ========================================================================== */

/**
 * A very simple reset that sits on top of Normalize.css.
 */

body,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
	margin: 0;
	padding: 0;
}

/**
 * Always declare margins in the same direction:
 * http://csswizardry.com/2012/06/single-direction-margin-declarations
 */

address,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
	margin-bottom: $spacer-base;
}

/**
 * Remove trailing margins from nested lists.
 */

li > {
	ol,
	ul {
		margin-bottom: 0;
	}
}

/**
 * Remove default table spacing.
 */

table {
	border-collapse: collapse;
	border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */

fieldset {
	min-width: 0; /* [1] */
	border: 0;
}

/**
 * 1. Inherit generic styles on some form elements.
 * 2. Inherit color on button elements, Safari `mousedown` color issue fix.
 */

button,
input,
optgroup,
select,
textarea {
	font-family: inherit; /* [1] */
	line-height: inherit; /* [1] */
}

button {
	color: inherit; /* [2] */
}
