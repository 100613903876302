/* =============================================================================
   BACKGROUNDS (utilities/_backgrounds.scss)
   ========================================================================== */

/* Colors
   ========================================================================== */

@each $key, $value in $colors {
	.u-bg-#{$key} {
		background-color: $value !important;
	}
}
