/* =============================================================================
   DEMO-ITEM (components/_demo-item.scss)
   ========================================================================== */

.c-demo-item {
	position: relative;
	display: block;
	margin-bottom: 1.875rem;
	box-shadow: 0 0 10px 3px rgba(0, 0, 0, .1);

	&:hover {
		&::before {
			opacity: .8;
		}

		&::after {
			opacity: 1;
		}
	}

	&::before,
	&::after {
		position: absolute;
		opacity: 0;
		transition: background-color .2s, opacity .2s;
	}

	&::before {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}

	&::after {
		content: 'View Demo';
		@extend .btn;
		@extend .btn--dark;
		@extend .btn--rounded;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		color: $color-dark-blue;
		background-color: $color-yellow;
	}
}



/* Variants
   ========================================================================== */

/**
 * Color variants
 */

.c-demo-item--blue {
	&:hover {
		&::before {
			background-color: $color-light-blue;
		}
	}
}

.c-demo-item--green {
	&:hover {
		&::before {
			background-color: $color-light-green;
		}
	}
}

.c-demo-item--purple {
	&:hover {
		&::before {
			background-color: $color-light-purple;
		}
	}
}

.c-demo-item--yellow {
	&:hover {
		&::before {
			background-color: $color-light-yellow;
		}
	}
}

.c-demo-item--red {
	&:hover {
		&::before {
			background-color: $color-light-red;
		}
	}
}
