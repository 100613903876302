/* =============================================================================
   TYPOGRAPHY (utilities/_typography.scss)
   ========================================================================== */

/* Alignment
   ========================================================================== */

.u-text-left {
	text-align: left !important;
}

.u-text-center {
	text-align: center !important;
}

.u-text-right {
	text-align: right !important;
}
