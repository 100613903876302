/* =============================================================================
   PAGE (elements/_page.scss)
   ========================================================================== */

/**
 * Simple page-level setup.
 *
 * 1. Set the default `color`, `font-family`, `font-size` and `line-height` for
 *    the entire project, sourced from our default variables.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 * 4. Setting @viewport causes scrollbars to overlap content in IE11 and Edge,
 *    so we force a non-overlapping, non-auto-hiding scrollbar to counteract.
 * 5. Change the default tap highlight to be completely transparent in iOS.
 * 6. Using the standard antialiasing technique instead of subpixel rendering to
 *    make fonts look smoother on Safari and Google Chrome.
*/

html {
	color: $color-base; /* [1] */
	font-family: $font-family-base; /* [1] */
	font-size: $font-size-base-mobile; /* [1] */
	line-height: $line-height-base; /* [1] */
	overflow-y: scroll; /* [2] */
	min-height: 100%; /* [3] */
	-ms-overflow-style: scrollbar; /* [4] */
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* [5] */
	-webkit-font-smoothing: antialiased; /* [6] */
	-moz-osx-font-smoothing: grayscale; /* [6] */

	@include media-breakpoint-up(md) {
		font-size: $font-size-base; /* [1] */
	}
}

/**
* IE10+ doesn't honor `<meta name="viewport">` in some cases.
*/

@at-root {
	@-ms-viewport {
		width: device-width;
	}
}
