/* =============================================================================
   MEDIA (elements/_media.scss)
   ========================================================================== */

/**
 * SVG
 */

svg {
	vertical-align: middle;
}
