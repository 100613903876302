/* =============================================================================
   BREAKPOINTS (tools/_breakpoints.scss)
   ========================================================================== */

/**
 * Breakpoint viewport sizes and media queries.
 *
 * Breakpoints are defined as a map of (name: minimum width), order from small
 * to large: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px).
 *
 * The map defined in the `$breakpoints` global variable is used as the
 * `$breakpoints` argument by default.
 */

/**
 * Name of the next breakpoint, or null for the last breakpoint.
 */

@function breakpoint-next($name, $breakpoints: $breakpoints, $breakpoint-names: map-keys($breakpoints)) {
	$n: index($breakpoint-names, $name);

	@return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

/**
 * Minimum breakpoint width. Null for the smallest (first) breakpoint.
 */

@function breakpoint-min($name, $breakpoints: $breakpoints) {
	$min: map-get($breakpoints, $name);

	@return if($min != 0, $min, null);
}

/**
 * Maximum breakpoint width. Null for the largest (last) breakpoint. The maximum
 * value is calculated as the minimum of the next one less 0.1.
 */

@function breakpoint-max($name, $breakpoints: $breakpoints) {
	$next: breakpoint-next($name, $breakpoints);

	@return if($next, breakpoint-min($next, $breakpoints) - 1px, null);
}

/**
 * Returns a blank string if smallest breakpoint, otherwise returns the name
 * with a dash infront. Useful for making responsive utilities.
 */

@function breakpoint-infix($name, $breakpoints: $breakpoints) {
	@return if(breakpoint-min($name, $breakpoints) == null, '', '-#{$name}');
}

/**
 * Media of at least the minimum breakpoint width. No query for the smallest
 * breakpoint. Makes the @content apply to the given breakpoint and wider.
 */

@mixin media-breakpoint-up($name, $breakpoints: $breakpoints) {
	$min: breakpoint-min($name, $breakpoints);

	@if $min {
		@media (min-width: $min) {
			@content;
		}
	} @else {
		@content;
	}
}

/**
 * Media of at most the maximum breakpoint width. No query for the largest
 * breakpoint. Makes the @content apply to the given breakpoint and narrower.
 */

@mixin media-breakpoint-down($name, $breakpoints: $breakpoints) {
	$max: breakpoint-max($name, $breakpoints);

	@if $max {
		@media (max-width: $max) {
			@content;
		}
	} @else {
		@content;
	}
}

/**
 * Media that spans multiple breakpoint widths. Makes the @content apply between
 * the min and max breakpoints.
 */

@mixin media-breakpoint-between($lower, $upper, $breakpoints: $breakpoints) {
	$min: breakpoint-min($lower, $breakpoints);
	$max: breakpoint-max($upper, $breakpoints);

	@if $min != null and $max != null {
		@media (min-width: $min) and (max-width: $max) {
			@content;
		}
	} @else if $max == null {
		@include media-breakpoint-up($lower) {
			@content;
		}
	} @else if $min == null {
		@include media-breakpoint-down($upper) {
			@content;
		}
	}
}

/**
 * Media between the breakpoint's minimum and maximum widths.
 * No minimum for the smallest breakpoint, and no maximum for the largest one.
 * Makes the @content apply only to the given breakpoint, not viewports any
 * wider or narrower.
 */

@mixin media-breakpoint-only($name, $breakpoints: $breakpoints) {
	$min: breakpoint-min($name, $breakpoints);
	$max: breakpoint-max($name, $breakpoints);

	@if $min != null and $max != null {
		@media (min-width: $min) and (max-width: $max) {
			@content;
		}
	} @else if $max == null {
		@include media-breakpoint-up($name) {
			@content;
		}
	} @else if $min == null {
		@include media-breakpoint-down($name) {
			@content;
		}
	}
}
