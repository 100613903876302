/* =============================================================================
   LAYOUT (objects/_layout.scss)
   ========================================================================== */

.o-layout {
	padding: 0 .9375rem;

	@include media-breakpoint-up(md) {
		display: flex;
	}
}



/* Items
   ========================================================================== */

.o-layout__item {
	flex-basis: 100%;
	padding: 0 .9375rem;

	@include media-breakpoint-up(md) {
		flex-basis: #{100% / 3};
	}
}
