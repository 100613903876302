/* =============================================================================
   CLEARFIX (tools/_clearfix.scss)
   ========================================================================== */

/* stylelint-disable declaration-no-important */

/**
 * Mixin to drop micro clearfix into a selector. Further reading:
 * http://www.cssmojo.com/the-very-latest-clearfix-reloaded/
 */

@mixin clearfix() {
	&::after {
		content: '' !important;
		display: block !important;
		clear: both !important;
	}
}
